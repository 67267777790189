import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useLiveControl from "../controlled/useLiveControl"

const PrivacyPolicy = ({ data, props }) => {

//  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Privacy Policy"}
        description={
          "We're committed to protecting your privacy and information. View our updated privacy policy now."
        }
      />
      {/* section-gap */}
      <section className="bg-light-gray py-3">
        <div className="container">
          <div
            className="text-center mt-5"
            dangerouslySetInnerHTML={{ __html: data.wpgraphql.page.content }}
          />
          <div className="row">
            <div className="col-12">
              {data.wpgraphql.page.terms.terms.map((item, i) => (
                <div key={item.fieldGroupName + i} className="mb-5">
                  <h3 className="text-blue mb-1">{item.question}</h3>
                  <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default PrivacyPolicy

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }

        terms {
          fieldGroupName
          terms {
            answer
            question
            fieldGroupName
          }
        }
      }
    }
  }
`
